import Axios from 'axios';
import router from "../router";
import Vue from 'vue';
import {
  Toast
} from 'vant';

Vue.use(Toast);

let headers = {
  // "accept": "application/json",
  // "content-type": "application/json",
  // 'connectin' : 'keep-alive',
  // "responseType": "json"


};
let url = {
  host: 'https://api.offline-sales.19jiang.cn'
}

const host = {

  'H5_HOST': process.env.NODE_ENV === 'production' ? url.host : 'http://192.168.2.204',
  'API_HOST': process.env.NODE_ENV === 'production' ? `${url.host}/v1` : `${url.host}/v1`,
  // 'API_HOST': process.env.NODE_ENV === 'production' ? `${url.host}/v1` : 'http://192.168.2.139:9501/v1',
  // 'SITE_URL': process.env.NODE_ENV === 'production' ? url.host : 'http://192.168.2.204:8080',
  'DEBUG': true,
  'ENCRYPTED': false,
  'BMAP_AK': '22bb7221fc279a484895afcc6a0bb33a',
  'TOKEN_KEY': 'RENINESHOP_TOKEN',
}


let axios = Axios.create({
  baseURL: host.API_HOST,
  headers: headers,
  timeout: 30000,

})



// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  config.headers.token = localStorage.getItem('dgt-token') || ''
  // 在发送请求之前做些什么
  return config;
}, function (error) {
  // 对请求错误做些什么
  // Message.error(error);
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么

  if (response.data.code == 0 && response.status == 200) {


    return response.data;


  } else {


    if (response.data.code == 9998) {

      router.replace({
        name: "login"
      })
    }

    Toast.fail({
      duration: 3000,
      message: response.data.message
    });


    return Promise.reject(response);
  }

}, function (error) {
  // 对响应错误做点什么
  Toast.fail('出错了，刷新或联系网管');

  return Promise.reject(error);
});



export default axios;