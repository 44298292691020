import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}





const routes = [{
    path: '/order',
    name: 'order',
    component: () => import('../views/order/index.vue'),
    meta: {
      title: "订单"
    }
  }, {
    path: '/final',
    name: 'final',
    component: () => import('../views/final/index.vue'),
    meta: {
      title: "支付完成"
    }
  }, {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/index.vue'),
    meta: {
      title: "登录"
    }
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/product/index.vue'),
    meta: {
      title: "产品列表"
    }
  }, {
    path: '/schedule',
    name: 'schedule',
    component: () => import('../views/schedule/index.vue'),
    meta: {
      title: "选择会场"
    }
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('../views/inventory/index.vue'),
    meta: {
      title: "订单信息"
    }
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment/index.vue'),
    meta: {
      title: "待支付"
    }
  },

  {
    path: '/',
    redirect: "product"

  },

  {
    path: 'd1',
    name: 'd1',
    component: () => import('../views/other/1.vue'),
    meta: {
      title: "全课表资料"
    }
  },

  {
    path: 'd2',
    name: 'd2',
    component: () => import('../views/other/2.vue'),
    meta: {
      title: "注意事项"
    }
  },

  {
    path: 'd3',
    name: 'd3',
    component: () => import('../views/other/3.vue'),
    meta: {
      title: "流程"
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach((to, from, next) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title;
  }
  next()
})


export default router